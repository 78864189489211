import "./UserSendMail.scss";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import RenderBonusType from "./RenderBonusType";
import { getFormatBonus, postMail } from "../../../../services/apiCmsService";
import { BonusType, GOLD, PIECE, RUBY, GEM, VIP_EXP, USER_EXP, ITEM_EQUIP, ITEM, HERO, SKIN, PET, AVATAR, ITEM_FARM, WEAPON } from "../../../../enum/BonusType";
import { IsJsonString } from "../../../../utils/Validate";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../../../redux/slices/dbSlice";

const UserSendMail = () => {
  const [userId, setUserId] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [bonus, setBonus] = useState("");
  const [search, setSearch] = useState("");
  const [type, setType] = useState("Gold");
  const [number, setNumber] = useState(1);
  const [expire, setExpire] = useState(-1);
  const [lock, setLock] = useState(1);
  const [key, setKey] = useState(1);
  const nightMode = useSelector((state) => state?.night?.mode);
  const [itemId, setItemId] = useState(1);
  const dispatch = useDispatch();
  const hasValue = useSelector((state) => state?.dbItem?.check);

  useEffect(() => {
    defaultBonus();
  }, []);

  const defaultBonus = async () => {
    if (!hasValue) {
      let data = await getFormatBonus();
      if (data && +data.EC === 0) {
        dispatch(update(data.DT));
        return;
      }
      if (data && +data.EC !== 0) {
        toast.error(data.EM);
        return;
      }

      if (!data) toast.error("Không nhận được dữ liệu!");

    }
  };

  const handleBtnClear = () => {
    setBonus("");
  };

  const handleBtnAddClick = () => {
    if (number <= 0 || number === undefined) {
      toast.warning("Số lượng phải lớn hơn 0");
      return;
    }
    if (expire < -1 || expire === 0) {
      toast.warning("Expire phải lớn hơn 0 hoặc bằng -1");
      setExpire(-1);
      return;
    }
    let id = BonusType(type);
    if (id === GOLD || id === GEM || id === VIP_EXP || id === USER_EXP || id === RUBY) {
      let has = bonus === "" ? "" : ",";
      setBonus(`${bonus}${has}${id},${+number}`);
    }
    if (id === ITEM_EQUIP) {
      let has = bonus === "" ? "" : ",";
      setBonus(`${bonus}${has}${id},${key},${+expire},${+lock}`);
      setExpire(-1);
    }
    if (id === ITEM) {
      let has = bonus === "" ? "" : ",";
      setBonus(`${bonus}${has}${id},${key},${+number}`);
      setExpire(-1);
    }
    if (id === WEAPON) {
      let has = bonus === "" ? "" : ",";
      setBonus(`${bonus}${has}${id},${key}`);
    }
    if (id === HERO) {
      let has = bonus === "" ? "" : ",";
      setBonus(`${bonus}${has}${id},${+number}`);
    }
    if (id === AVATAR) {
      toast.error("Bonus này đang phát triển!");
    }
    if (id === PET) {
      let has = bonus === "" ? "" : ",";
      setBonus(`${bonus}${has}${id},${key},${itemId}`);
    }
    if (id === SKIN) {
      if (key === 1) {
        toast.error("Bonus này đang phát triển!");
        return;
      }
      let has = bonus === "" ? "" : ",";
      setBonus(`${bonus}${has}${id},${key},${itemId}`);
    }
    if (id === ITEM_FARM) {
      let has = bonus === "" ? "" : ",";
      setBonus(`${bonus}${has}${id},${key},${itemId},${+number}`);
    }
    if (id === PIECE) {
      let has = bonus === "" ? "" : ",";
      setBonus(`${bonus}${has}${id},${key},${itemId},${+number}`);
    }
    // if (id === 13) {
    //   let has = bonus === "" ? "" : ",";
    //   setBonus(`${bonus}${has}${id},${key},${+number}`);
    //   setExpire(-1);
    // }
  };

  const handleChangeType = async (event) => {
    setType(event.target.value);
    setNumber(1);
    setItemId(1);
    setKey(1);
  }

  const handleBtnSendClick = async () => {
    if (userId === "") {
      toast.warning("Cần nhập User Id");
      return;
    }
    if (title === "") {
      toast.warning("Cần nhập Title");
      return;
    }
    if (!IsJsonString(`[${bonus}]`)) {
      toast.error("Bonus không hợp lệ!");
      return;
    }
    let data = await postMail(userId, title, content, bonus);
    if (data && +data.EC === 0) {
      toast.success(data.EM);
    }
    if (data && +data.EC !== 0) {
      toast.error(data.EM);
    }
    setBonus("");
  };

  return (
    <div className="user-mail-container">
      <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"} >Send Mail User</div>
      <div className={+nightMode === 0 ? "day-body-content" : "night-body-content"}>
        <div className="row1">
          <div className="input-userid">
            <FloatingLabel label="User Id">
              <Form.Control
                className="form-dark"
                type="number"
                autoFocus
                value={userId}
                onChange={(event) => setUserId(event.target.value)}
              />
            </FloatingLabel>
          </div>
          <div className="input-title">
            <FloatingLabel label="Tiêu đề">
              <Form.Control
                className="form-dark"
                type="text"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
              />
            </FloatingLabel>
          </div>
        </div>
        <div className="input-content">
          <FloatingLabel label="Nội dung">
            <Form.Control
              className="form-dark"
              type="text"
              as="textarea"
              style={{ height: '100px' }}
              value={content}
              onChange={(event) => setContent(event.target.value)}
            />
          </FloatingLabel>
        </div>
        <div className="bonus-content">
          <FloatingLabel
            controlId="floatingBonus"
            label="Bonus"
            className="bonus-input"
          >
            <Form.Control
              type="text"
              className="form-dark"
              value={bonus}
              onChange={(event) => setBonus(event.target.value)}
            />
          </FloatingLabel>
          <button
            className="btn btn-danger btn-action "
            onClick={handleBtnClear}
          >
            Clear
          </button>
        </div>
        <div className="form-content">
          <Form.Select
            className="select-content form-dark"
            defaultValue={type}
            onChange={(event) => handleChangeType(event)}
          >
            <option value="Gold">Gold</option>
            <option value="Gem">Gem</option>
            <option value="ItemEquipment">Item Equipment</option>
            <option value="UserExp">User Exp</option>
            <option value="Hero">Hero</option>
            <option value="Item">Item</option>
            <option value="Weapon">Weapon</option>
            <option value="Avatar">Avatar</option>
            <option value="VipExp">Vip Exp</option>
            <option value="Pet">Pet</option>
            <option value="Skin">Skin</option>
            <option value="ItemFarm">Item Farm</option>
            <option value="ItemPoint">Item Point</option>
            <option value="Piece">Piece</option>
            <option value="Ruby">Ruby</option>
          </Form.Select>

          {RenderBonusType(
            type, number, setNumber, expire, setExpire,
            handleBtnAddClick, key, setKey, lock, setLock,
            itemId, setItemId, nightMode, search, setSearch
          )}
        </div>
        <div className="bot-content">
          <button
            className={+nightMode === 0 ? "btn btn-primary btn-send" : "btn btn-info btn-send"}
            onClick={handleBtnSendClick}
          >
            Gửi
          </button>
        </div>
      </div>
    </div >
  );
};

export default UserSendMail;
