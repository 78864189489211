import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import "./UserSendMail.scss";
import { BonusType } from "../../../../enum/BonusType";
import { useSelector } from "react-redux";
import { MONSTER_PIECE, WEAPON_PIECE } from "../../../../enum/PieceType";

const RenderBonusType = (
  type, number, setNumber, expire, setExpire,
  handleBtnAddClick, key, setKey, lock, setLock,
  itemId, setItemId, nightMode, search, setSearch
) => {
  let id = BonusType(type);



  const handleChangeKey = (key) => {
    setKey(key);
    setItemId(key === "1" ? 2 : 1);
  }


  const fSetItemId = itemId => {
    setItemId(itemId);
  }


  const { ListEquip, ListItem, ListWeapon, ListItemSeed, ListItemFarm, ListItemTool, ListItemPiece, ListItemFood, ListMonster, ListPet, ListDameSkin, ListChatFrame, ListTrial, ListHero } = useSelector((state) => state?.dbItem);
  let ListItemMap = ListItem;

  const findItem = (search) => {
    setSearch(search);
    let listFind = ListItemMap && ListItemMap.length > 0 ?
      ListItemMap.filter((item) => {
        return search.toLowerCase() === "" ? item : item.id.toString().toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase());
      }) : "";
    if (listFind.length > 0) {
      setKey(listFind[0].id);
    }
    if (search === "") {
      ListItemMap = ListItem;
      return;
    }
  }

  switch (id) {
    case 1: //Gold
    case 2: //Gem
    case 4: //UserExp
    case 15: //UserExp
    case 9: //Vip Exp
      return (
        <div className={+nightMode === 0 ? "format-bonus" : "format-bonus-dark"}>
          <FloatingLabel className="number-content" label="Add Value">
            <Form.Control
              type="number"
              value={number}
              onChange={(event) => setNumber(event.target.value)}
            />
          </FloatingLabel>
          <button
            className="btn btn-success btn-add"
            onClick={handleBtnAddClick}
          >
            Add
          </button>
        </div>
      );
    case 3: //ItemEquipment
      return (
        <div className={+nightMode === 0 ? "format-bonus" : "format-bonus-dark"}>
          <Form.Select
            className="select-key"
            defaultValue={key}
            onChange={(event) => setKey(event.target.value)}
          >
            {ListEquip &&
              ListEquip.length > 0 &&
              ListEquip.map((item, index) => {
                return <option value={item.id}>{item.id + ". " + item.name}</option>;
              })}
          </Form.Select>
          <FloatingLabel className="select-expire" label="Expire">
            <Form.Control
              className="select-expire"
              type="number"
              value={expire}
              onChange={(event) => setExpire(event.target.value)}
            />
          </FloatingLabel>
          <Form.Select
            className="select-item"
            defaultValue={lock}
            onChange={(event) => setLock(event.target.value)}          >
            <option value={0}>Không khóa</option>;
            <option value={1}>Khóa</option>;
          </Form.Select>
          <button className="btn btn-success btn-add"
            onClick={handleBtnAddClick}
          >
            Add
          </button>
        </div>
      );
    case 5: //Hero
      return (
        <div className={+nightMode === 0 ? "format-bonus" : "format-bonus-dark"}>
          <Form.Select
            className="select-key"
            defaultValue={key}
            onChange={(event) => setNumber(event.target.value)}
          >
            {ListHero && ListHero.length > 0 &&
              ListHero.map((item, index) => {
                return <option eventKey={`format5_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
              })}
          </Form.Select>
          <button
            className="btn btn-success btn-add"
            onClick={handleBtnAddClick}
          >
            Add
          </button>
        </div>
      );
    case 6: //Item
      return (
        <div className={+nightMode === 0 ? "format-bonus" : "format-bonus-dark"}>
          <FloatingLabel className="search-content" label="Search">
            <Form.Control
              value={search}
              type="text"
              onChange={(event) => findItem(event.target.value)}
            />
          </FloatingLabel>
          <Form.Select
            className="select-key"
            defaultValue={key}
            onChange={(event) => setKey(event.target.value)}
          >
            {ListItemMap &&
              ListItemMap.length > 0 &&
              ListItemMap.filter((item) => {
                return search.toLowerCase() === "" ? item : item.id.toString().toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase());
              }).map((item, index) => {
                return <option eventKey={`format16_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
              })}
          </Form.Select>
          <FloatingLabel className="number-content" label="Add Value">
            <Form.Control
              type="number"
              value={number}
              onChange={(event) => setNumber(event.target.value)}
            />
          </FloatingLabel>
          <button
            className="btn btn-success btn-add"
            onClick={handleBtnAddClick}
          >
            Add
          </button>
        </div>
      );
    case 7: // weapon
      return (
        <div className={+nightMode === 0 ? "format-bonus" : "format-bonus-dark"}>
          <Form.Select
            className="select-key"
            defaultValue={key}
            onChange={(event) => setKey(event.target.value)}
          >
            {ListWeapon &&
              ListWeapon.length > 0 &&
              ListWeapon.map((item, index) => {
                return <option eventKey={`format7_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
              })}
          </Form.Select>
          <button
            className="btn btn-success btn-add"
            onClick={handleBtnAddClick}
          >
            Add
          </button>
        </div>
      );
    case 10://pet
      return (<div className={+nightMode === 0 ? "format-bonus" : "format-bonus-dark"}>
        <Form.Select
          className="select-key"
          defaultValue={key}
          onChange={(event) => handleChangeKey(event.target.value)}
        >
          <option value={1}>Quái thú</option>;
          <option value={2}>Thú cưng</option>;
        </Form.Select>
        {+key === 1 && <Form.Select
          className="select-item"
          defaultValue={itemId}
          onChange={(event) => setItemId(event.target.value)}
        >
          {ListMonster && ListMonster.length > 0 &&
            ListMonster.map((item, index) => {
              return <option eventKey={`format1412_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
            })}
        </Form.Select>
        }
        {+key === 2 && <Form.Select
          className="select-item"
          defaultValue={itemId}
          onChange={(event) => setItemId(event.target.value)}
        >
          {ListPet && ListPet.length > 0 &&
            ListPet.map((item, index) => {
              return <option eventKey={`format1413_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
            })}
        </Form.Select>
        }
        <button
          className="btn btn-success btn-add"
          onClick={handleBtnAddClick}
        >
          Add
        </button>
      </div>);
    case 11://skin
      return (<div className={+nightMode === 0 ? "format-bonus" : "format-bonus-dark"}>
        <Form.Select
          className="select-key"
          defaultValue={key}
          onChange={(event) => handleChangeKey(event.target.value)}>
          <option value={1}>Hero Skin</option>;
          <option value={2}>Dame Skin</option>;
          <option value={3}>Khung Chat</option>;
          <option value={4}>Bóng Mờ</option>;
        </Form.Select>
        {+key === 2 && <Form.Select
          className="select-item"
          defaultValue={itemId}
          onChange={(event) => setItemId(event.target.value)}
        >
          {ListDameSkin && ListDameSkin.length > 0 &&
            ListDameSkin.map((item, index) => {
              return <option eventKey={`format1112_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
            })}
        </Form.Select>
        }
        {+key === 3 && <Form.Select
          className="select-item"
          defaultValue={itemId}
          onChange={(event) => setItemId(event.target.value)}
        >
          {ListChatFrame && ListChatFrame.length > 0 &&
            ListChatFrame.map((item, index) => {
              return <option eventKey={`format1113_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
            })}
        </Form.Select>
        }
        {+key === 4 && <Form.Select
          className="select-item"
          defaultValue={itemId}
          onChange={(event) => setItemId(event.target.value)}
        >
          {ListTrial && ListTrial.length > 0 &&
            ListTrial.map((item, index) => {
              return <option eventKey={`format1114_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
            })}
        </Form.Select>
        }
        <button
          className="btn btn-success btn-add"
          onClick={handleBtnAddClick}
        >
          Add
        </button>
      </div>);
    case 12: //ItemFarm
      return (
        <div className={+nightMode === 0 ? "format-bonus" : "format-bonus-dark"}>
          <Form.Select
            className="select-key"
            defaultValue={key}
            onChange={(event) => handleChangeKey(event.target.value)}
          >
            <option value={1}>Hạt giống</option>;
            <option value={2}>Nông sản</option>;
            <option value={3}>Công cụ</option>;
            <option value={4}>Thức ăn</option>;
          </Form.Select>
          {+key === 1 && <Form.Select
            className="select-item"
            defaultValue={itemId}
            onChange={(event) => setItemId(event.target.value)}
          >
            {ListItemSeed && ListItemSeed.length > 0 &&
              ListItemSeed.map((item, index) => {
                return <option eventKey={`format121_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
              })}
          </Form.Select>
          }
          {+key === 2 && <Form.Select
            className="select-item"
            defaultValue={itemId}
            onChange={(event) => setItemId(event.target.value)}
          >
            {ListItemFarm && ListItemFarm.length > 0 &&
              ListItemFarm.map((item, index) => {
                return <option eventKey={`format122_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
              })}
          </Form.Select>
          }
          {+key === 3 && <Form.Select
            className="select-item"
            defaultValue={itemId}
            onChange={(event) => setItemId(event.target.value)}
          >
            {ListItemTool && ListItemTool.length > 0 &&
              ListItemTool.map((item, index) => {
                return <option eventKey={`format123_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
              })}
          </Form.Select>
          }
          {+key === 4 && <Form.Select
            className="select-item"
            defaultValue={itemId}
            onChange={(event) => setItemId(event.target.value)}
          >
            {ListItemFood && ListItemFood.length > 0 &&
              ListItemFood.map((item, index) => {
                return <option eventKey={`format124_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
              })}
          </Form.Select>
          }
          <FloatingLabel label="Add Value">
            <Form.Control
              className="number-content"
              type="number"
              value={number}
              onChange={(event) => setNumber(event.target.value)}
            />
          </FloatingLabel>
          <button
            className="btn btn-success btn-add"
            onClick={handleBtnAddClick}
          >
            Add
          </button>
        </div>
      );
    case 14: //Piece
      return (
        <div className={+nightMode === 0 ? "format-bonus" : "format-bonus-dark"}>
          <Form.Select
            className="select-key"
            defaultValue={key}
            onChange={(event) => handleChangeKey(event.target.value)}
          >
            <option value={1}>Mảnh vũ khí</option>;
            <option value={2}>Mảnh quái thú</option>;
          </Form.Select>
          {+key === WEAPON_PIECE && <Form.Select
            className="select-item"
            defaultValue={itemId}
            onChange={(event) => fSetItemId(event.target.value)}
          >
            {ListItemPiece && ListItemPiece.length > 0 &&
              ListItemPiece.filter((piece) => {
                return piece.type === WEAPON_PIECE;
              }).map((item, index) => {
                return <option eventKey={`format1411_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
              })}
          </Form.Select>
          }
          {+key === MONSTER_PIECE && <Form.Select
            className="select-item"
            defaultValue={itemId}
            onChange={(event) => fSetItemId(event.target.value)}
          >
            {ListItemPiece && ListItemPiece.length > 0 &&
              ListItemPiece.filter((piece) => {
                return piece.type === MONSTER_PIECE;
              }).map((item, index) => {
                return <option eventKey={`format1412_${index}`} value={item.id}>{item.id + ". " + item.name}</option>;
              })}
          </Form.Select>
          }
          <FloatingLabel label="Add Value">
            <Form.Control
              className="number-content"
              type="number"
              value={number}
              onChange={(event) => setNumber(event.target.value)}
            />
          </FloatingLabel>
          <button
            className="btn btn-success btn-add"
            onClick={handleBtnAddClick}
          >
            Add
          </button>
        </div>
      );
    default:
      return <></>;
  }
};

export default RenderBonusType;
